body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
ol,
ul {
  list-style: none;
}
th {
  text-align: left;
}
a {
  text-decoration: none !important;
  color: inherit !important;
}
a:active {
  color: inherit !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}
q:before,
q:after {
  content: '';
}
html,
body {
  /*min-width: 890px; */
  width: 100%;
  height: 100%;
}
button {
  outline: none;
}
.adress-item {
  /* color: red; */
}
.recruit-content {
  margin-left: 14px;
  margin-bottom: 11px;
  text-align: left;
  font-size: 14px;
  color: #999;
}
.adress-nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 250px;
}
#root {
  font-family: 'Light';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #666666;
  min-height: 100%;
  min-width: 960px;
}
@media screen and (max-width: 960px) {
  html {
    font-size: 55px!important;
  }
}
/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
.case_carousel::-webkit-scrollbar,
.product_carousel::-webkit-scrollbar {
  position: absolute;
  top: 0;
  left: 0;
  width: 2.5px;
  height: 2.5px;
  background-color: #F5F5F5;
}
.case_carousel {
  scrollbar-color: #3958c8 #F5F5F5;
  /* 滑块颜色  滚动条背景颜色 */
  scrollbar-width: thin;
  /* 滚动条宽度有三种：thin、auto、none */
}
/*定义滚动条轨道 内阴影+圆角*/
.case_carousel::-webkit-scrollbar-track,
.product_carousel::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.01);
  border-radius: 10px;
  background-color: #F5F5F5;
}
/*定义滑块 内阴影+圆角*/
.case_carousel::-webkit-scrollbar-thumb,
.product_carousel::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #3958c8;
}
